<template>
  <div>
    <div class="text-xl-m w-full mb24 text-align-center risk-title">
      {{ $t('免责声明') }}
    </div>
    <div class="w-full text-align-center mb24">
      <img src="@/assets/img/futures/icon-risk.svg" width="100" height="100" />
    </div>
    <div class="text-sm-r risk-detail">
      {{ $t('我确认期货合约可能极具波动性，存在损失全部合约保证金的风险。我理解永续合约没有到期日。我将根据的个人目标和情况，独立评估每笔交易的适用性和风险至关重要。我确认我具备足够的专业知识和技能来交易永续合约，并我对自己的投资决策负有唯一责任。在法律允许的范围内，我承认并同意，我不会因我的交易活动（包括但不限于财务损失、系统故障或其他技术问题）而让HBL及/或其关联公司、运营商、管理层或员工承担任何责任和损失。') }}
    </div>
  </div>

  <div class="mt24 flex-row-flex-end">
    <hk-button class="text-sm-m contract-btn contract-close-btn" @click="onCancel">
      {{ $t('关闭') }}
    </hk-button>
    <hk-button class="contract-btn text-sm-m  contract-submit-btn"  @click="onSubmit">
      {{ $t('继续') }}
    </hk-button>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { useContractStore } from '@/store/contract';

const contractStore = useContractStore();
const { updateStep, closeDialog  } = contractStore;

const onCancel = () => {
  updateStep(false, 3);
  closeDialog();
};

const onSubmit = () => {
  updateStep(false, 3);
  updateStep(true, 4);
};
</script>

<style lang="scss">
.text-align-center {
  text-align:center;
}

</style>

<style scoped lang="scss">
.text-align-center {
  text-align:center;
}
.risk-title {
  color: var(--gray-700);
}
.risk-detail {
  color: var(--gray-600);
}

.dark {
  .risk-title {
    color: var(--base-white);
  }
  .risk-detail {
    color: var(--gray-50);
  }
}

</style>