import { ViteSSG } from 'vite-ssg';
import { ID_INJECTION_KEY } from 'element-plus/es';
import 'element-plus/dist/index.css';
import App from './App.vue';
import { useAppStore } from '@/store/app';
import { useRouterEach, routes } from './router';
import { pinia } from './store';
import { i18n } from '@/lang/i18n';
// 自定义拖拽
import Directives from '@/utils/directives';
// import ElementPlus from 'element-plus';
// import zhCn from 'element-plus/dist/locale/zh-cn.mjs';
import { registerApp } from '@/components/hk-element';

export const createApp = ViteSSG(
  App,
  // router 实例初始化设置
  {
    routes
  },
  (context: any) => {
    const { app, router, initialState, isClient, onSSRAppRendered } = context;
    registerApp(app); // 注册hk elements（封装的element plus）
    app
      .use(i18n)
      .use(pinia)
      .use(Directives);

    if (isClient) {
      pinia.state.value = (initialState.pinia) || {};

    } else {
      app.provide(ID_INJECTION_KEY, {
        prefix: 100,
        current: 0
      });

      onSSRAppRendered(() => {
        initialState.pinia = pinia.state.value;
      });
    }

    // 因偶尔会出现注册监听事件发生在 webView didFinish 回调 flutterInAppWebViewPlatformReady 之后，导致页面获取不到 app 的登录状态，
    // In that case, you can just check if window?.flutter_inappwebview?.callHandler != null. If it's true, then it means it is ready to be used. [https://github.com/pichillilorenzo/flutter_inappwebview/issues/1820]
    try {
      if (window && window?.flutter_inappwebview != null && window?.flutter_inappwebview?.callHandler != null) {
        console.log('>>>>>>> start to call changeJsbridgeStatus() func');
        useAppStore(pinia).changeJsbridgeStatus(true);
      }
    } catch (e: any) {
      console.error(e);
    }

    // 保存事件处理函数的引用
    const flutterInAppWebViewReadyHandler = (event:any) => {
      useAppStore(pinia).changeJsbridgeStatus(true);
      // 获取 app 配置常量(区别版本差异)
      useAppStore(pinia).getAppConstant();
    };
  
    // 添加事件监听器 flutter WebView 已准备就绪，执行一些操作,认为是在app打开，具备使用window.flutter_inappwebview的条件
    window.addEventListener('flutterInAppWebViewPlatformReady', flutterInAppWebViewReadyHandler);
  
    onBeforeUnmount(() => {
      window.removeEventListener('flutterInAppWebViewPlatformReady', flutterInAppWebViewReadyHandler);
    });

    // Object.values(import.meta.glob<{ install: UserModule }>('./modules/*.ts', { eager: true }))
    //   .forEach(i => i.install?.(context));

    // 加载路由钩子事件
    useRouterEach(router);
  }
);
