// eslint-disable-next-line
// @ts-nocheck
import { i18n } from '@/lang/i18n';
// import { pinia } from '@/store';
// import { useCommonStore } from '@/store/common';
// import { isPC } from '@/utils/tools';

const { t } = i18n.global;
// const commonStore = useCommonStore(pinia);

// 存管路由 24个
const futures = [
  {
    path: '/orders/futures',
    component: () => import('@/pages/futures-order/Index.vue'),
    meta: {
      layout: 'default',
      auth: 'login',
      title: t('合约订单')
    }
  },
  {
    path: '/futures',
    component: () => import('@/pages/futures/Index.vue'),
    meta: {
      layout: 'exchange',
      theme: true,
      props: { header: { border: false } },
      title: 'USDT Perpetual Contracts',
      keywords: 'futures tradinge, Bitcoin Trading, Bitcoin Futures,BTC price',
      description: 'Trade BTCUSDT perpetual contracts on HashKey Futures'
    }
  },
  {
    path: '/futures/:symbolPath',
    component: () => import('@/pages/futures/Index.vue'),
    meta: {
      layout: 'exchange',
      theme: true,
      props: { header: { border: false } },
      title: 'USDT Perpetual Contracts',
      keywords: 'futures tradinge, Bitcoin Trading, Bitcoin Futures,BTC price',
      description: 'Trade BTCUSDT perpetual contracts on HashKey Futures'
    }
  },
  {
    path: '/h5/futures',
    component: () => import('@/pages/futures/H5.vue'),
    meta: {
      layout: 'h5',
      title: 'USDT Perpetual Contracts',
      keywords: 'futures tradinge, Bitcoin Trading, Bitcoin Futures,BTC price',
      description: 'Trade BTCUSDT perpetual contracts on HashKey Futures',
      class: { layout: 'dark' }
    }
  },
  {
    path: '/h5/calculator',
    component: () => import('@/pages/futures/h5/Calculator.vue'),
    meta: {
      layout: '',
      auth: 'ignore',
      title: t('合约计算器')
      // class: { layout: 'dark' }
    }
  },
  {
    path: '/futures/info',
    component: () => import('@/pages/futures-info/Index.vue'),
    meta: {
      layout: 'default',
      title: t('HashKey Global - 期货信息 {\'|\'} 指数合约详情'),
      keywords: t('HashKey Global、期货信息、指数合约、实时价格、交易量、深度图表分析'),
      description: t('HashKey Global提供最新的指数合约信息，包括实时价格、交易量、深度图表分析等。立即访问获取更多期货市场数据！')
    }
  },
  {
    path: '/futures/rule',
    component: () => import('@/pages/futures-rule/Index.vue'),
    meta: {
      layout: 'default',
      title: t('合约规则')
    }
  },
  {
    path: '/futures-activity',
    component: () => import('@/pages/futures-activity/Index.vue'),
    meta: {
      layout: 'default',
      title: t('合约邀新活动')
      // props: { header: { border: false } },
      // class: { layout: 'dark' }
    }
  },
  {
    path: '/h5/futures-activity',
    component: () => import('@/pages/futures-activity/h5/Index.vue'),
    meta: {
      layout: 'h5',
      auth: 'ignore',
      title: t('合约邀新活动')
    }
  },
  {
    path: '/assets/future',
    component: () => import('@/pages/assets/Future.vue'),
    meta: {
      layout: 'default',
      auth: 'login',
      title: t('合约账户')
    }
  },
  {
    path: '/assets/spot', // old 
    component: () => import('@/pages/assets/Spot.vue'),
    meta: {
      layout: 'default',
      auth: 'login',
      title: t('现货账户'),
      ssg: {}
    }
  },
  {
    path: '/h5/open-futures',
    component: () => import('@/pages/futures/h5/OpenFutures.vue'),
    meta: {
      layout: 'h5',
      auth: 'ignore',
      title: t('开通合约账户')
    }
  }
];

export default futures;