<template>
  <div class="fifthDlg text-align-center">
    <div class="w-full mb24 mt50">
      <div class="success-icon" />
    </div>
    <div class="w-full mb4 text-display-xs-m success-title">
      {{ $t('您已成功开通合约交易') }}
    </div>
    <div class="w-full text-md-r success-subtitle">
      {{ $t('转入资金、开始交易！') }}
    </div>
    <div class="mt56 h40 flex-row-space-between items-center">
      <hk-button class="text-sm-m contract-btn  contract-close-btn" @click="onCancel" >
        {{ $t('以后再说') }}
      </hk-button>
      <div>
        <hk-button v-if="btnType === BtnType.Transfer" class="text-sm-m contract-btn contract-close-btn" @click="onClick('transfer')" >
          <div class="transfer-icon mr8" />{{ $t('划转') }}
        </hk-button>
        <hk-button v-if="btnType === BtnType.Trade" class="text-sm-m contract-btn contract-close-btn" @click="onClick('trade')" >
          <div class="trade-icon mr8" />{{ $t('去交易') }}
        </hk-button>
        <hk-button class="contract-btn  text-sm-m  contract-submit-btn" @click="onClick('deposit')">
          <div class="deposit-icon mr8" />{{ $t('充值') }}
        </hk-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { computed, ref } from 'vue';
import { useCommonStore } from '@/store/common';
import { useContractStore, BtnType } from '@/store/contract';

const route = useRoute();
const router = useRouter();

const commonStore = useCommonStore();
const contractStore = useContractStore();
const { updateStep, closeDialog, btnType, openVisible } = contractStore;

const darkTheme = computed(() => {
  console.log('darktheme:', commonStore.isDark);
  return commonStore.isDark == 'dark';
});

const onClick = (val: string) => {
  if (val == 'transfer') {
    updateStep(false, 5);
    updateStep(true, 6);
  } else if (val == 'trade') {
    router.push('/futures');
  } else {
    router.push('/custody/assetView/savingAccount/deposit');
  }
  closeDialog();
};

const onCancel = () => {
  updateStep(false, 5);
  closeDialog();
};
</script>
<style lang="scss" scoped>
.fifthDlg {
  .text-align-center {
    text-align: center;
  }
  .success-title {
    color: var(--gray-900);
  }
  .success-subtitle {
    color: var(--gray-600);
  }
  .later {
    color: var(--gray-700);
  }
  .common-btn {
    height: 40px;
  }
  .success-icon {
    width: 56px;
    height: 56px;
    margin: 0 auto;
    background: url(@/assets/img/futures/success.svg) center center/100% 100% no-repeat;
  }
  .transfer-icon {
    width: 14px;
    height: 12px;
    background: url(@/assets/img/futures/transfer.svg) center center/100% 100% no-repeat;
  }
  .trade-icon {
    width: 17px;
    height: 17px;
    background: url(@/assets/img/futures/trade.svg) center center/100% 100% no-repeat;
  }
  .deposit-icon {
    width: 17px;
    height: 17px;
    background: url(@/assets/img/futures/deposit.svg) center center/100% 100% no-repeat;
  }
  
}

.dark {
  .success-title {
    color: var(--base-white);
  }
  .success-subtitle {
    color: var(--gray-300);
  }
  .later {
    color: var(--gray-300);
  }
  .success-icon {
    background: url(@/assets/img/futures/dark-success.svg) center center/100% 100% no-repeat;
  }
  .transfer-icon {
    background: url(@/assets/img/futures/dark-transfer.svg) center center/100% 100% no-repeat;
  }
  .trade-icon {
    width: 17px;
    height: 17px;
    background: url(@/assets/img/futures/dark-trade.svg) center center/100% 100% no-repeat;
  }
  .deposit-icon {
    width: 17px;
    height: 17px;
    background: url(@/assets/img/futures/dark-deposit.svg) center center/100% 100% no-repeat;
  }

}

</style>