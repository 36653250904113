// import { RouteRecordRaw, RouteMeta, RouteComponent } from 'vue-router';
// eslint-disable-next-line
// @ts-nocheck
import { RouteRecordRaw } from 'vue-router';

import commonRoutes from './config/common';
import activityRoutes from './config/activity';
import growthRoutes from './config/growth';
import noSEORoutes from './config/noSEO';
import tradeRoutes from './config/trade';
import userRoutes from './config/user';
import custodyRoutes from './config/custody';
import tradeRoutes from './config/trade';
import futuresRoutes from './config/futures';

// 不同布局需要的组件
const layoutComponents: Record<string, any> = {
  default: {
    header: () => import('@/components/layout/components/Header.vue'),
    footer: () => import('@/components/layout/hk-components/Footer.vue')
  },
  exchange: {
    header: () => import('@/components/layout/components/Header.vue')
  },
  login: {
    header: () => import('@/components/layout/components/LoginHeader.vue'),
    footer: () => import('@/components/layout/components/LoginFooter.vue')
  },
  agent: {
    header: () => import('@/components/layout/components/AffiliatesHeader.vue')
  },
  footer: {
    footer: () => import('@/components/layout/hk-components/Footer.vue')
  },
  h5Default: {
    header: () => import('@/components/layout/hk-components/H5Header.vue')
  },
  blank: {}
};

/**
 * 转换布局格式
 * 解决路由加参数无法跳转Bug，以及浏览器前进后退键无法使用问题
 * 本质原因：必须在App.vue文件里写根路由的 router-view 并确保router-view处于最外层
 *
 * @param routes
 */
const routesAddLayout = (routes: RouteRecordRaw[]) => {
  return routes.map((route: RouteRecordRaw) => {
    const meta: RouteMeta = route.meta || {};
    const layout = String(meta.layout || '');
    const components: Record<string, RouteComponent> = layoutComponents[layout];
    const routeComponents = route.components || {};

    // 路由default组件换赋值方式
    if (route.component) {
      routeComponents.default = route.component;
    }

    // 路由布局组件赋值
    if (components) {
      Object.keys(components).forEach(key => {
        routeComponents[key] = components[key];
      });

      route.components = routeComponents;
    }

    return route;
  });
};

/**
 * 路由集中处理
 *
 * @param routes 路由配置
 * @returns
 */
const routesHandle = (routes: RouteRecordRaw[]) => {
  routes = routesAddLayout(routes);

  return routes;
};

// 106个路由
export default routesHandle([...commonRoutes, ...activityRoutes, ...growthRoutes, ...noSEORoutes, ...tradeRoutes, ...custodyRoutes, ...userRoutes, ...futuresRoutes,
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404/'
  }
]);
