<template>
  <el-upload
    ref="fileUpload"
    v-model:file-list="fileList"
    class="drag-uploader"
    :class="['size-' + size]"
    v-bind="$attrs"
    name="upload_image_file"
    :action="action"
    :headers="headers"
    drag
    :multiple="multiple"
    with-credentials
    :accept="supportType"
    :limit="maxFiles"
    :before-upload="beforeAvatarUpload"
    :on-change="handleChange"
    :on-exceed="exceedUpload"
    list-type="picture"
  >
    <div v-if="!slots.trigger" class="drag-uploader-box text-align-center">
      <img src="@/assets/img/icon/icon-upload.svg" width="40" height="40" alt="hashkey upload">
      <p class="fz14 lh20 weight-400 mt12 gray-600" v-html="tipsText" />
      <p class="mt4 text-3 fz12 lh18 pl8 pr8">
        {{ t('file-support') }}
        {{ uploadTip }}
      </p>
    </div>
    <slot v-else name="trigger" />
    <template #file="{ file }">
      <div class="flex-row-space-between items-center mtb list radius12 w-full">
        <div class="flex-row-space-between items-center w-full">
          <img :src="getImgSrc(file.name)" width="40" class="mr16">
          <div class="w-full">
            <p class="text-sm-m gray-900 overflow-wrap-anywhere">
              {{ shortFileName(file.name) }}
            </p>
            <p class="text-xs-m gray-500">
              {{ `${(file.size / 1024).toFixed(2)} kb` }}
            </p>
            <el-progress
              v-if="file.status != 'success'" :percentage="progress"
              class="w-full progress"
            />
          </div>
        </div>
        <img
          src="@/assets/img/kyc/delete.svg" width="20" class="cursor-pointer"
          @click="onFileDelete(file)"
        >
      </div>
    </template>
  </el-upload>
</template>
<i18n>
  {
    "en-US": {
      "file-support": "Supported file extensions: ",
      "form-error": {
        "file-limit": "Upload up to {n} files"
      }
    },
    "zh-CN": {
      "file-support": "支持扩展名：",
      "form-error": {
        "file-limit": "上传文件最多{n}份"
      }
    },
    "zh-TW": {
      "file-support": "支持擴展名：",
      "form-error": {
        "file-limit": "上傳文件最多{n}份"
      }
    }
  }
</i18n>
<script lang="ts">
// namespaced components
export default {
  name: 'HkUpload'
};
</script>
<script setup lang="ts">
import img_png from '@/assets/img/kyc/png.svg';
import img_jpg from '@/assets/img/kyc/jpg.svg';
import img_pdf from '@/assets/img/kyc/pdf.svg';
import img_jpeg from '@/assets/img/kyc/jpeg.svg';
import { shortFileName } from '@/utils/tools';
import { apiHost } from '@/config/setup';
import { toast } from '@/utils/toast';
import { getLanguage } from '@/lang/i18n';
import { getCookie } from '@/utils/cookie';
import type { UploadFile, UploadProps } from 'element-plus';
import type { DefaultConfigState, HkUploadFile } from '@/pages/assets/custody/components/types';
import { Ref } from 'vue';
const slots = useSlots();

// export interface DefaultConfigState {
//   defaultSize: number;
//   defaultMinPx: number;
//   defaultMaxPx: number;
// }
// export interface HkUploadFile {
//   fileKey?: string,
//   name?: string,
//   size?: string,
//   type?: string,
//   voucherId?: string,
//   url?: string
// }
interface HkUploadFileType {
  size?: 'default' | 'large'
  // 初始文件列表
  fileList?: Array<any>,
  // 单个文件最大
  maxSize?: number,
  // 最多文件数量, multiple为true时生效
  maxFiles?: number,
  // 是否支持多个文件上传
  multiple?: boolean,
  // 支持上传的文件类型
  supportType?: string[],
  // 是否需要刷新初始文件列表 兼容ZA充值
  refreshList?: boolean,
  // onSuccess
  onSuccess?: () => void,
  // 自定义远程上传路由
  customAction?: string,
  // 需要调用接口删除时可配置该方法
  onDelete?: (id: string) => boolean
}
const props = withDefaults(defineProps<HkUploadFileType>(), {
  size: 'default',
  maxSize: 10 * 1024 * 1024,
  maxFiles: 5,
  multiple: true,
  supportType: ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'] as any,
  reFreshList: false
});

const emits = defineEmits<{
  (event: 'success', evt: HkUploadFile[] | []): void;
  (event: 'error', evt: UploadFile[] | null): void;
  (event: 'delete', file: HkUploadFile): void;
}>();

// 默认配置
const defaultConfig = ref<DefaultConfigState>({
  defaultSize: 10 * 1024 * 1024,
  defaultMinPx: 300,
  defaultMaxPx: 8000
});
const defaultSupportType = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf']; // 默认支持上传的文件类型
const action: any = ref(props.customAction || `${apiHost.api}/api/v3/kyc/file/upload_image?r= ${Math.random().toString(32).substring(2)}&c_token=${getCookie('glb_c_token')}`);
const headers: any = ref({
  'Accept-Language': getLanguage().toLowerCase()
});
const { t } = useI18n();
// 初始化文件列表
const initFiles = computed(() => formatFileList(props.fileList || []));
// 格式化文件
const formatFileList = (list: any[]) => {
  return list?.map((x: any) => ({
    type: x?.type || x?.fileType,
    size: x.size,
    name: x?.name || x?.filePath,
    voucherId: x?.voucherId,
    response: { url: x?.filePath || x?.key || x?.fileKey, code: '0' },
    status: 'success',
    fileKey: x?.key || x?.fileKey,
    key: x?.key || x?.fileKey
  }));
};
watch(() => props.fileList, () => {
  fileList.value = initFiles.value;
});
// 支持的文件类型
const supportFileType = computed(() => {
  const { supportType } = props;
  return supportType ? supportType?.join(',') : defaultSupportType.join(',');
});
// 支持的文件类型组成的字符串
const uploadTip = computed(() => {
  const typeList: string[] = props.supportType || defaultSupportType;
  let tip = '';
  typeList?.map((x: string, i: number) => {
    const index = x.indexOf('/');
    if (index > 0) {
      tip += x.slice(index + 1) + (i < typeList.length - 1 ? ', ' : '.');
    }
  });
  return tip;
});
const fileList: any = ref(initFiles.value || []); // eslint-disable-line
const supportType: Ref<string> = ref(supportFileType); // eslint-disable-line
const progress: any = ref(90);
const tipsText: any = ref('');
const maxSize = ref<number>(props.maxSize as number);
const maxFiles = ref<number>(props.maxFiles as number);
const { defaultSize } = defaultConfig.value;
const deleteFiles = ref<any[]>([]);
let time: any = '';
tipsText.value = t('%s1点击上传%s2或将文件拖拽到这里').replace('%s1', '<em class="text-link mr4">').replace('%s2', '</em>');

/** 重新赋值filelist */
const resetFileList = (newList: any) => {
  fileList.value = formatFileList(newList);
};
defineExpose({ resetFileList });
/**
 * 上传前
 */
const beforeAvatarUpload: UploadProps['beforeUpload'] = rawFile => {
  if (!rawFile) {
    return false;
  }
  // if (!supportType.value.includes(rawFile?.type)) {
  //   return false;
  // }
  if (rawFile.size > (maxSize.value || defaultSize)) {
    toast.error(t('上传文件超出限制'));
    return false;
  }
  time = setInterval(() => {
    if (progress.value < 99) {
      progress.value += 1;
    }
    if (progress.value == 99) {
      clearInterval(time);

    }
  }, 80);
};

const getImgSrc = (fileName: string) => {
  if (!fileName) {
    return '';
  }
  if (fileName.includes('jpeg')) {
    return img_jpeg;
  } else if (fileName.includes('jpg')) {
    return img_jpg;
  } else if (fileName.includes('pdf')) {
    return img_pdf;
  } else {
    return img_png;
  }
};

const handleChange: UploadProps['onChange'] = (fileInfo: UploadFile, list) => {
  if (deleteFiles.value?.includes(fileInfo?.uid)) {
    return;
  }
  if (fileInfo?.status) {
    // 显示文件，并且不设置表单数据
    fileList.value = list;
    if (['success', 'removed', 'fail'].some(x => x == fileInfo?.status)) {
      let val: any[] = [];
      let files: any = [];
      let fileListUploaded = true; // 标记上传列表是否都结束
      list?.map((item: any, index) => {
        if (item?.status == 'success' && item?.response?.url) {
          const response: any = item?.response;
          if (response?.url) {
            val.push({
              fileKey: response?.url,
              name: item?.name,
              size: item?.size,
              type: item?.raw?.type || item?.type,
              voucherId: item?.voucherId,
              url: response?.url,
              key: response?.url
            });
          }
          files.push(item);
        }
        if (item?.status == 'uploading') {
          fileListUploaded = false;
        }
      });
      // 上传都结束后赋值
      if (fileListUploaded) {
        emits('success', val);
        // props.onSuccess(val);
        // 除了上传失败，其他都需要重新设置显示的文件列表
        if (fileInfo.status != 'fail') {
          fileList.value = files;
        }
      }

      // 错误处理
      if (fileInfo.status == 'fail' || fileInfo.status == 'success' && !fileInfo?.response?.url) {
        console.error('file upload failed');
        emits('error', list.filter(file => file?.status === 'fail' || file.status == 'success' && !file?.response?.url));
      }
    }
  } else {
    // 报错会影响表单数据，需重新赋值
    let val: any[] = [];
    fileList.value?.map((item: any) => {
      if (item.status && item.status == 'success') {
        val.push({
          fileKey: item.response.url,
          name: item.name,
          size: item.size,
          type: item.type,
          key: item.response.url,
          url: item.response.url
        });
      }
    });
    setTimeout(() => {
      emits('success', val);
      // props.onSuccess(val);
    }, 1000);
  }
};
const onFileDelete = async(file: any) => {
  let newFileList = [];
  emits('delete', file);
  // props.onDelete存在则调用接口进行删除  删除失败则停止运行
  if (file?.voucherId && props.onDelete && !(await props.onDelete(file?.voucherId))) {
    return;
  }
  if (file.fileKey) {
    // 初始加载的文件
    newFileList = fileList.value?.filter((item: any) => item.fileKey !== file.fileKey);
  } else {
    newFileList = fileList.value?.filter((item: any) => item.uid !== file.uid);
  }
  fileList.value = newFileList;
  deleteFiles.value = [...deleteFiles.value, file.uid];
  let files: any[] = [];
  newFileList.map((item: any) => {
    if (item?.response?.url) {
      files.push({
        fileKey: item.response.url,
        name: item.name,
        size: item.size,
        type: item?.raw?.type || item?.type,
        voucherId: item?.voucherId,
        key: item.response.url,
        url: item.response.url
      });
    }
  });
  emits('success', files);
  // props.onSuccess(files);
};

const exceedUpload: UploadProps['onExceed'] = (files, uploadFiles) => {
  if (files.length + uploadFiles.length > props.maxFiles) {
    toast.error(t('form-error.file-limit', { n: props.maxFiles }));
  }
};

// 兼容 在ZA-STEP3中，用户点击保存之后需要刷新文件列表
watch(() => props.fileList?.[props.fileList?.length - 1]?.['voucherId'], value => {
  if (props.refreshList && value) {
    resetFileList(value);
  }
});

</script>

<style lang="scss" scoped>
.drag-uploader {
  background: #ffffff;
  border-radius: 12px;
  margin-bottom: 24px;

  :deep(.el-upload-dragger) {
    padding: 0;
  }

  :deep(.el-upload) {
    width: 100%;

    &:focus .el-upload-dragger {
      border-color: var(--gray-800) !important;
    }

    .el-upload-dragger:hover {
      border: 1px dashed var(--gray-800) !important;
    }
  }

  :deep(.el-progress-bar) {
    height: 4px !important;
  }

  :deep(.el-progress-bar__inner) {
    height: 4px !important;
    background: linear-gradient(270deg, #c9c9c9 0%, #6a6a6a 100%);
  }

  :deep(.el-progress-bar__outer) {
    height: 4px !important;
  }

  :deep(.el-progress__text) {
    top: -17px;
  }

  &.size-large {
    .drag-uploader-box {
      height: 222px;
      padding-top: 50px;
    }
  }

  .drag-uploader-box {
    padding-top: 16px;
    min-width: 300px;
    height: 126px;
    margin: auto;
  }

  .file-content {
    width: calc(100% - 16px - 40px);
  }

  .progress {
    height: 2px;
    top: 3px !important;
  }

  .overflow-wrap-anywhere {
    overflow-wrap: anywhere;
  }
}
</style>

