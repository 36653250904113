<template>
  <el-dialog
    v-if="contractVisible"
    v-model="contractVisible"
    title="Confirm"
    :width="first ? '400px': '544px'"
    :modal="true"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :class="getClass()"
    align-center
  >
    <!--STEP1 开通合约交易 -->
    <ContractFirst v-if="first" />
    <!--STEP2 选择杠杆倍数 -->
    <ContractTwice  v-if="twice" />
    <!--STEP3 风险提示-->
    <ContractThird v-if="third" />
    <!--STEP4 交易问卷-->
    <ContractFourth v-if="fourth" />
    <!--STEP5 成功开通 -->
    <ContractFifth v-if="fifth" />
    
    <span
      class="contract-dialog-closed cursor-pointer"
      @click="onClose"
    />

  </el-dialog>

  <!-- 划转 -->
  <Transfer
    v-if="transferVisible"
    :visible="transferVisible"
    :to="transferParams.to"
    :from="transferParams.from"
    :token="transferParams.token"
    :transfer-type="'TradeToFuture'"
    @confirm="onAfterTransfer"
    @update:visible="onCloseTransfer"
  />

  <!-- 声明确认 -->
  <Statement v-if="statementVisible" />
</template>

<script lang="ts" setup>
import { useContractStore } from '@/store/contract';
import Transfer from '@/components/Transfer.vue';
import ContractTwice from './ContractTwice.vue';
import ContractThird from './ContractThird.vue';
import ContractFourth from './ContractFourth.vue';
import ContractFifth from './ContractFifth.vue';
import ContractFirst from './ContractFirst.vue';
import Statement from './Statement.vue';

const contractStore = useContractStore();
const { updateStep, closeDialog } = contractStore;
const {
  first,
  twice,
  third,
  fourth,
  fifth,
  transferVisible,
  contractVisible,
  statementVisible
} = storeToRefs(contractStore);

const transferParams: any = reactive({
  from: '',
  to: '',
  token: '',
  transferType: ''
});

const getClass = () => {
  const list = ['contractDialog'];
  if (first.value) {
    list.push('firstDialog');
  } else if (twice) {
    list.push('twiceDialog');
  } else if (fifth) {
    list.concat(['text-align-center', 'fifthDlg']);
  }
  return list;
};

const onClose = () => {
  contractStore.closeDialog();
};

const onAfterTransfer = () => {
  contractStore.onRefresh();
};

const onCloseTransfer = () => {
  updateStep(false, 6);
  contractStore.closeDialog();
};

onMounted(() => {
  contractStore.initData();
});

</script>
<style lang="scss">
.contractDialog {
  border-radius: 12px;
  .el-dialog__header {
    display: none;
  }
  .el-dialog__footer {
    display: none;
  }
  .el-dialog__body {
    border-radius: 12px;
    background: var(--base-white) !important;
    box-shadow: 0 8px 8px -4px rgba(16, 24, 40, 0.03), 0 20px 24px -4px rgba(16, 24, 40, 0.08);
    padding: 24px;

    .icon-warning {
      width: 115px;
      height: 100px;
      margin: auto;
      background: url(@/assets/img/contract/btc.png) center/cover no-repeat;
    }
    .icon-close {
      width: 24px;
      height: 24px;
      background: url(@/assets/img/common/icon-close.svg) center/cover no-repeat;
    }
    .detail {
      text-align: left;
    }
  }
  .contract-dialog-closed {
    position: absolute;
    top: 25px;
    right: 24px;
    width: 24px;
    height: 24px;
    background: url(@/assets/img/icon/icon-close.png) 0 0/24px 24px no-repeat;
  }
}
.firstDialog {
  .el-dialog__body {
    padding: 24px 24px 32px !important;
    text-align: center;
  }
}
.contract-btn {
  min-width: 100px;
  width: auto;
  padding: 0 10px !important;
  height: 40px;
    
}
.contract-close-btn {
  color: var(--gray-700);
  box-shadow: none;
  background: var(--base-white);
  border: 1px solid var(--gray-300);
  &:hover {
    color: var(--gray-700);
    background: var(--base-white);
    border: 1px solid var(--gray-300);
  }
}
.contract-submit-btn {
  color: var(--base-white);
  box-shadow: none;
  background: var(--gray-900);
  border: 1px solid var(--gray-900);
  &:hover {
    color: var(--base-white);
    background-color: var(--gray-900);
    border: 1px solid var(--gray-900);
  }
}
.dark {
  .contractDialog {
    .el-dialog__body {
      background: var(--gray-800) !important;
      color: var(--base-white) !important;
      box-shadow: 0 8px 8px -4px rgba(16, 24, 40, 0.03), 0 20px 24px -4px rgba(16, 24, 40, 0.08);
      .icon-close {
        background: url(@/assets/img/hk-spot/icon-close-dark.svg) center/cover no-repeat;
      }
      .btn-confirm {
        color: var(--gray-800) !important;
        background: var(--gray-100) !important;
        box-shadow: none;
      }
    }

    .contract-close-btn {
      color: var(--base-white);
      box-shadow: none;
      background: var(--gray-800);
      border: 1px solid var(--gray-600);
      &:hover {
        color: var(--base-white);
        background: var(--gray-800);
        border: 1px solid var(--gray-600);
      }
    }
  }
  .contract-submit-btn {
    color: var(--gray-700);
    box-shadow: none;
    background: var(--base-white);
    border: 1px solid var(--base-white);
    &:hover {
      color:  var(--gray-700);
      background: var(--base-white);
      border: 1px solid var(--gray-600);
      border: 1px solid var(--base-white);
    }
  }
}
  </style>
