
<template>
  <el-dialog
    v-model="statementVisible"
    title=""
    width="544px"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    align-center
  >
    <div class="statementBox">
      <div class="text-xl-m statementTitle mb24" >
        {{ $t('声明') }}
      </div>
      <div class="text-sm-r statementDetail mb16" >
        {{ $t('永续合约的风险较高。请确认在使用期货合约前，您已学习和理解合约交易指南') }}
      </div>
      <div class="link text-md-m">
        <a :href="getFileLink()"  target='_blank' class="text-md-m file-link cursor-pointer">
          {{ $t('点击查看合约交易指南') }}
        </a>
      </div>
      <div class="flex-row-flex-end mt24">
        <hk-button class="contract-btn text-sm-m  contract-submit-btn" @click="onConfirm">
          {{ $t('确认') }}
        </hk-button>
      </div>
    </div>
    <span
      class="contract-dialog-closed cursor-pointer"
      @click="onClose"
    />
  </el-dialog>
</template>

<script lang="ts" setup>
import { getLanguage } from '@/lang/i18n';
import { useContractStore } from '@/store/contract';

const language = getLanguage();
const contractStore = useContractStore();
const { statementVisible } = storeToRefs(contractStore);
const { onConfirmStatement, updateStep } = contractStore;

// 确认声明
const onConfirm = () => {
  onConfirmStatement(true); 
  updateStep(false, 7);
};

// 点击关闭则未声明确认
const onClose = () => {
  onConfirmStatement(false); 
  updateStep(false, 7);
};

const getFileLink = () => {
  const languageStr = language.toLocaleLowerCase();
  const wholeLink = `https://support.global.hashkey.com/hc/${languageStr}`;
  return `${wholeLink}/categories/13507063598620`;
};

</script>
<style lang="scss" scoped>

.dark {
  .statementBox {
    .statementTitle {
      color: var(--base-white);
    }
    .statementDetail {
      color: var(--gray-50);
    }
  }
}

.statementBox {
  .statementTitle {
    text-align: center;
    color: var(--gray-700);
  }

  .statementDetail {
    color: var(--gray-600);
  }

  .link {
    color:  var(--primary-500);
  }

}
.contract-dialog-closed {
  position: absolute;
  top: 25px;
  right: 24px;
  width: 24px;
  height: 24px;
  background: url(@/assets/img/icon/icon-close.png) 0 0/24px 24px no-repeat;
}

</style>