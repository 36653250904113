
import { kycApi } from '@/config/kyc';
import { i18n } from '@/lang/i18n';
import { toast } from '@/utils/toast';
import CONST from '@/config/const';
const { t } = i18n.global;
export const useKycInfoStore = defineStore('kycInfoStore', {
  state: () => ({
    // kyc信息
    kycInfo: <any>{},
    personalIdentificationType: <any>null,
    poaStatus: 0,
    poaCountryList: [],
    informationList: reactive<any>([
      {
        label: t('个人信息'),
        key: 'personalStatus',
        // href: '/user/start-certification',
        href: '/user/base-auth',
        index: 1
      },
      {
        label: `<div>${t('地址证明')}</div><div>${t('（如需）')}</div>`,
        key: 'addressStatus',
        href: '/user/kycaddress',
        index: 2
      },
      {
        label: t('财务信息'),
        key: 'financialStatus',
        href: '/user/tax',
        index: 3
      },
      {
        label: t('协议签署'),
        key: 'signatureStatus',
        href: '/user/signature',
        index: 4
      }
    ]),
    examinationList: reactive<any>([
      {
        label: t('投资经验问卷'),
        key: 'riskAssessmentStatus',
        href: '/user/kycrishappraisal',
        index: 1
      }, {
        label: t('虚拟货币知识考试'),
        key: 'vaTestStatus',
        href: '/user/knowledgeytest',
        index: 2
      }
    ]),
    kycInfoReady: <boolean>false // 标识kyclevel接口是否请求完成
  }),
  getters: {
    // 获取kyc信息
    getKycInfo(state) {
      const { kycInfo } = state;
      return kycInfo;
    },
    // KYC 已认证
    kycCertified({ kycInfo: { totalAuditStatus } }) {
      return totalAuditStatus === CONST.KycTotalStatusEnum.PASS;
    },
    // KYC 未认证
    kycNotCertified({ kycInfo: { totalAuditStatus } }) {
      // 考虑到网络延迟问题，必须保证 totalAuditStatus 有内容
      return typeof totalAuditStatus !== 'undefined' && totalAuditStatus !== CONST.KycTotalStatusEnum.PASS;
    },
    getInformationList(state) {
      const { kycInfo, informationList } = state;
      const newList: any = [];
      Object.keys(kycInfo).forEach((key: string) => {
        if (key == 'personal') {
          Object.keys(kycInfo[key]).forEach((item: string) => {
            informationList.forEach((listItem: any, listIndex: any) => {
              if (listItem.key == item) {
                newList.push({
                  label: listItem.label,
                  key: listItem.key,
                  index: listItem.index,
                  href: listItem.href,
                  ...kycInfo[key][item]
                });
              }
            });
          });
        }
      });
      return newList.sort((a: any, b: any) => {
        return a.index - b.index;
      });
    },
    getPersonalIdentificationType(state) {
      const { personalIdentificationType } = state;
      return personalIdentificationType;
    },
    getCustodyInfo(state) {
      return state.kycInfo.personal?.custodyApplyStatus || state.kycInfo.corporate?.custodyApplyStatus;
    }
  },
  actions: {
    getRedirectUrlByStatus() {
      let url = '';
      if (this.getCustodyInfo.auditStatus == CONST.KycMaterialAuditStatusEnum.MATERIAL_STATUS_UNKNOWN) {
        kycApi.openCustodyApply()
          .then((data: any) => {
            if (data.success) {
              url = '/user/hashkeycustody';
            } else {
              toast.error(data.message);
            }
          });
      } else if (this.getCustodyInfo.auditStatus == CONST.KycMaterialAuditStatusEnum.MATERIAL_STATUS_EMPTY || this.getCustodyInfo.auditStatus == CONST.KycMaterialAuditStatusEnum.MATERIAL_STATUS_REJECT) {
        url = '/user/hashkeycustody';
      } else if (this.getCustodyInfo.auditStatus == CONST.KycMaterialAuditStatusEnum.MATERIAL_STATUS_REVIEW) {
        url = '/user/kyc?source=user';
      }
      return url;
    },
    requestKycInfo() {
      kycApi.getUserKycInfo()
        .then((data: any) => {
          if (data.success) {
            this.kycInfo = data.data;
            this.personalIdentificationType = data.data.personal?.personalIdentificationType == 0 ? 3 : data.data.personal?.personalIdentificationType;
          }
          this.kycInfoReady = true;
        });
    },
    getPoaStatus() {
      kycApi.getPoaStatus()
        .then((data: any) => {
          if (data.success) {
            this.poaStatus = data.data.idAsPoaStatus;
            this.poaCountryList = JSON.parse(data.data.idAsPoaCountries);
          }
          this.kycInfoReady = true;
        });
    },
    setPersonalIdentificationType(type: any) {
      this.personalIdentificationType = type;
    }
  }
});
