<template>
  <div class="icon-warning" />
  <p class="text-lg-sb hk-header-color-2 mt24">
    {{ $t('开通永续合约') }}
  </p>
  <p class="text-sm-r hk-header-color-1 mt8">
    {{ $t('可根据您的交易策略做多或者做空，最高提供10倍杠杆') }}
  </p>
  <div class="w-full mt24">
    <hk-button
      class="false-primary-lg text-md-m h40 base-white w-220 flex1 btn-confirm"
      @click="onConfirm"
    >
      {{ $t('立即开通') }}
    </hk-button>
  </div>
</template>
<script lang="ts" setup>
import { useContractStore } from '@/store/contract';
const contractStore = useContractStore();
const { updateStep } = contractStore;

const onConfirm = () => {
  updateStep(false, 1);
  updateStep(true, 2);
};
</script>

<style lang="scss">
.contractDialog {
  border-radius: 12px;
  .el-dialog__header {
    display: none;
  }
  .el-dialog__footer {
    display: none;
  }
  .el-dialog__body {
    border-radius: 12px;
    background: var(--base-white) !important;
    box-shadow: 0 8px 8px -4px rgba(16, 24, 40, 0.03), 0 20px 24px -4px rgba(16, 24, 40, 0.08);
    padding: 24px;

    .btn-confirm {
      width: 220px;
      margin: 0 auto;
    }

    .icon-warning {
      width: 115px;
      height: 100px;
      margin: auto;
      background: url(@/assets/img/contract/btc.png) center/cover no-repeat;
    }
    .icon-close {
      width: 24px;
      height: 24px;
      background: url(@/assets/img/common/icon-close.svg) center/cover no-repeat;
    }
    .detail {
      text-align: left;
    }
  }
  .contract-dialog-closed {
    position: absolute;
    top: 25px;
    right: 24px;
    width: 24px;
    height: 24px;
    background: url(@/assets/img/icon/icon-close.png) 0 0/24px 24px no-repeat;
  }
}
.firstDialog {
  .el-dialog__body {
    text-align: center;
  }
}
.contract-close-btn {
  color: var(--gray-700);
  box-shadow: none;
  background: var(--base-white);
  border: 1px solid var(--gray-300);
  &:hover, &:focus {
    color: var(--gray-700);
    background: var(--base-white);
    border: 1px solid var(--gray-300);
  }
}
.contract-submit-btn {
  color: var(--base-white);
  box-shadow: none;
  background: var(--gray-900);
  border: 1px solid var(--gray-900);
  &:hover, &:focus {
    color: var(--base-white);
    background-color: var(--gray-900);
    border: 1px solid var(--gray-900);
  }
}
.dark {
  .contractDialog {
    .el-dialog__body {
      background: var(--gray-800) !important;
      color: var(--base-white) !important;
      box-shadow: 0 8px 8px -4px rgba(16, 24, 40, 0.03), 0 20px 24px -4px rgba(16, 24, 40, 0.08);
      .icon-close {
        background: url(@/assets/img/hk-spot/icon-close-dark.svg) center/cover no-repeat;
      }
      .btn-confirm {
        color: var(--gray-800) !important;
        background: var(--gray-100) !important;
        box-shadow: none;
      }
    }

    .contract-close-btn {
      color: var(--base-white);
      box-shadow: none;
      background: var(--gray-800);
      border: 1px solid var(--gray-600);
      &:hover, &:focus {
        color: var(--base-white);
        background: var(--gray-800);
        border: 1px solid var(--gray-600);
      }
    }
    .contract-submit-btn {
      color: var(--gray-700);
      box-shadow: none;
      background: var(--base-white);
      border: 1px solid var(--base-white);
      &:hover:not(.is-disabled), &:focus:not(.is-disabled) {
        color:  var(--gray-700);
        background: var(--base-white);
        border: 1px solid var(--base-white);
      }
      &.is-disabled {
        background-color: var(--gray-600) !important;
        color: var(--gray-400);
        border: 1px solid var(--gray-600);

        &:hover, &:focus {
          background-color: var(--gray-600) !important;
          color: var(--gray-400);
          border: 1px solid var(--gray-600);
        }
      }
    }
  }
}
  </style>