
import { isApp, callSupportTimezoneAdjustment } from '@/utils/appBridge';
export const useAppStore = defineStore('appStore', {
  state: () => ({
    jsbridgeStatus: <boolean>false, // jsbridge状态，true表示jsbridge ready
    supportTimezoneAdjustment: <boolean>false // 是否支持时区调整
  }),
  getters: {},
  actions: {
    changeJsbridgeStatus(val: boolean) {
      this.jsbridgeStatus = val;
    },
    getAppConstant() {
      if (isApp) {
        callSupportTimezoneAdjustment().then((result:any) => {
          this.supportTimezoneAdjustment = !!result;
        });
      } else {
        this.supportTimezoneAdjustment = false;
      }
    }
  }
});
