<template>
  <div  v-loading="loading">
    <div class="question-box">
      <div class="text-xl-m w-full transaction-title  mb24 ">
        {{ $t('开通合约账户') }}
      </div>
      <div class="text-sm-r transaction-title mb16">
        {{ $t('最后一步，完成问卷即可开始合约交易') }}
      </div>
      <div v-for="(item,index) in questionList" :key="index" >
        <div class="mb16 question-title">
          {{ `${index+1}. ${item.title}` }}
        </div>
        <el-radio-group 
          v-model="item.value"
          :class="[
            'question-radio',
            'w-full',
            'mb24',
          ]"
          @change="(val:any) => onRadioChange(index, val)"  
        >
          <!-- 第一题标识正确 -->
          <el-radio 
            v-for="(x ,i) in item?.options"
            :key="x" 
            :class="[
              btnClicked && index === 0 ? 'correct': '',
              btnClicked && index !== 0 ? ((x.id === item.answerId) ? 'correct' : 'error') : '',
              'w-full',
              'clear-margin'
            ]" 
            :label="x.id"
            :value="x.id" 
          >
            {{ x.name }}
          </el-radio>
        </el-radio-group>
      </div>
    </div>
    <div class="mt24 flex-row-flex-end">
      <hk-button class="text-sm-m contract-btn contract-close-btn" @click="onCancel">
        {{ $t('关闭') }}
      </hk-button>
      <hk-button class=" contract-btn text-sm-m  contract-submit-btn" :loading="subLoading" :disabled="btnDisabled"  @click="onCheck">
        {{ $t('提交') }}
      </hk-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { useCommonStore } from '@/store/common';
import { useContractStore } from '@/store/contract';
import { toast } from '@/utils/toast';

const route = useRoute();
const commonStore = useCommonStore();
const contractStore = useContractStore();
const { updateStep, onAnswer, closeDialog  } = contractStore;
const { questionList, statement } = storeToRefs(contractStore);
const btnClicked = ref<boolean>(false); // 提交按钮是否点击
const subLoading = ref<boolean>(false);
const loading = ref<boolean>(false);

const { t } = useI18n();

const btnDisabled = computed(() => {
  if (!questionList.value) {
    return true;
  }
  if (questionList.value?.find(x => !x.value)) {
    return true;
  } else {
    return false;
  }
});

const onCancel = () => {
  updateStep(false, 4);
  closeDialog();
};

const onRadioChange = (itemKey: number, val: string) => {
  onAnswer(itemKey, val); // 答题更新回答结果

  const { result } = questionList.value?.[0];
  // 回答第一题时 回答错误则打开声明弹框
  if (itemKey === 0 && !result) {
    updateStep(true, 7);
  }
 
};

const onCheck = () => {
  // 提交之后检查问卷结果
  btnClicked.value = true;
  const list = [...questionList.value];
  // 第一题答错且未声明 打开弹框
  if (list?.findIndex(x => !x.result) === 0 && !statement.value) {
    updateStep(true, 7);
    return;
  }
  // 允许提交的情况  1.第一题答错且声明确认,剩余题目回答正确;  2.所有问题都回答正确
  if ((list?.findIndex(x => !x.result) === 0 && statement.value && list?.slice(1).every(x => x.result)) || list.every(x => x.result)) {
    onSubmit();
  } else {
    toast.warning(t('请选择合适的答案'));
  }
  
};

const onSubmit = async() => {
  subLoading.value = true;
  await contractStore.onSubmitContract();
  subLoading.value = false;
};

const getQuestionList = async() => {
  loading.value = true;
  await contractStore.getQuestionList();
  loading.value = false;
};

onMounted(() => {
  getQuestionList();
});

</script>

<style lang="scss">
.question-radio {
  .el-radio {
    padding: 4px 8px;
    height: auto !important;

    .el-radio__label {
      color: var(--gray-600) !important;
      white-space: break-spaces;
    }
    &.is-checked.correct {
      .el-radio__input {
        background-image: url('@/assets/img/spot/success.svg');
        background-size: 100%;
      }
      .el-radio__inner {
        opacity: 0;
      }
    }
    &.is-checked.error {
      .el-radio__input {
        background-image: url('@/assets/img/spot/error.svg');
        background-size: 100%;
      }
      .el-radio__inner {
        opacity: 0;
      }
    }

  }
  .el-radio:hover {
    background-color: var(--gray-50) !important;
    border-radius: 4px !important;

    .el-radio__input {
      border-color: var(--gray-600);
    }
  }

  .el-radio__inner {
    border-radius: 50%;
    border: 2px solid  var(--gray-500);

    &:hover {
      border-color: var(--gray-600);
    }
  }
  .clear-margin {
    margin-right: 0 !important
  }

}

.dark {
  .question-radio {
    .el-radio {
      color: var(--gray-400) !important;
      .el-radio__label {
        color: var(--gray-400) !important;
      }

      .el-radio__input.is-checked + .el-radio__label {
        color: var(--gray-400) !important;
      }
      .el-checkbox__input.is-checked .el-checkbox__inner, .el-radio__input.is-checked .el-radio__inner {
        background-color: var(--base-white) !important;
        border-color: var(--base-white) !important;

        &::after {
          background-color: var(--gray-800) !important;
        }
      }

      &.is-checked.correct {
        .el-radio__input {
          background: url(@/assets/img/spot/success.svg) center/cover no-repeat;
        }
        .el-radio__inner {
          opacity: 0;
        }
      }
      &.is-checked.error {
        .el-radio__input {
          background: url(@/assets/img/spot/error.svg) center/cover no-repeat;
        }
        .el-radio__inner {
          opacity: 0;
        }
      }
    
    }
    .el-radio:hover {
      background-color: rgba(255, 255, 255, 0.08) !important;
      .el-radio__input {
        border-color: var(--gray-300);
      }
    }
    .el-radio__inner {
      border: 2px solid  var(--gray-300) !important;
      &:hover {
        border-color: var(--gray-300) !important;
        background-color: var(--gray-700);
      }
    }
  }
  .el-loading-mask {
    background-color:  rgba(38, 39, 43,0.1) !important;
  }
}

</style>

<style scoped lang="scss">
.question-box {
  min-height: 500px;
}
.text-align-center {
  text-align: center;
}
.transaction-title {
  color: var(--gray-700);
}
.question-title {
  color: var(--gray-900);
}
.question-detail {
  color: var(--gray-600);
}
.dark {
  .transaction-title {
    color: var(--base-white);
  }
  .question-title {
    color: var(--gray-25);
  }
  .question-detail {
    color: var(--gray-400);
  }
}
</style>