/**
 * @file 网站配置
 */
// import { requiredNumber } from 'element-plus/es/components/table-v2/src/common';
import { isClient } from '@vueuse/shared';
import siteConfig from './site';

// 网站支持的语言,seo生成的多语言html根据这个列表生成
export const langs: string[] = [
  'zh-CN',
  'en-US',
  // 'ko-KR',
  // 'ja-JP',
  // 'vi-VN',
  // 'es-ES',
  // 'zh-TW',
  // 'ru-RU',
  // 'tr-TR',
  'zh-TW'
  // 'ja-JP',
  // 'pt-PT'
  // 'pt-BR',
  // 'fr-FR',
  // 'th-TH',
  // 'id-ID',
];

// 网站默认语言(准备删除)
export const lang = 'zh-CN';

// 语言缓存key
export const STORE_LANG = 'lang';

export const STORE_LEGAL_KEY = 'legal_flag';

export const defaultLang = isClient ? (localStorage.getItem(STORE_LANG) || 'en-US') : 'en-US';

export const defaultLegalFlag = () => isClient ? (localStorage.getItem(STORE_LEGAL_KEY) || 'USD') : 'USD';

// 退出登录不跳登录页的页面
export const stayRoute = [
  '/',
  '/spot',
  '/h5/vip',
  '/vip',
  '/vipZone',
  '/activity/genesisVip',
  '/activity/mine',
  '/activity/newUserTask',
  '/activity/contractTrading',
  /^\/spot\/[a-zA-Z0-9]{1,20}_[a-zA-Z0-9]{1,20}$/,
  '/markets',
  '/futures',
  /^\/futures\/[a-zA-Z0-9\-]{1,20}$/,
  '/about-us',
  '/futures-activity',
  '/copytrading',
  '/download',
  '/card-deposit',
  '/invite',
  '/safeWay',
  /^\/safeWay\//,
  '/buy-cryptos',
  /^\/buy-cryptos\//,
  /\/activity\/api-customer-rewards/,
  '/hskPoints',
  '/hashBrokerage',
  '/support-fee',
  '/h5/support-fee'
];

// 退出登录不带backurl
export const noBackurl = ['/user/kycaudit', '/user/base-auth', '/user/kycaddress', '/user/tax', '/user/signature'];

// 谨慎！！不要修改，避免把测试环境地址带去线上
const env = import.meta.env.MODE;
const isHKFirstOrigin = env == 'stg' ? 'www' : 'hk';
// 接口域名(production放在最后面，防止被变量覆盖)
const apis:any = {
  [env]: `https://bapi-glb.${env}.hashkeydev.com`,
  production: 'https://bapi-glb.hashkey.com'
};
const wss:any = {
  [env]: `wss://bws-glb.${env}.hashkeydev.com`,
  production: 'wss://bws-glb.hashkey.com'
};
const apps:any = {
  [env]: `https://mapi-glb.${env}.hashkeydev.com`,
  production: 'https://mapi-glb.hashkey.com'
};
// 国际站host
const web:any = {
  [env]: `https://global.${env}.hashkeydev.com`,
  production: 'https://global.hashkey.com'
};
// 香港站host
const webHK:any = {
  [env]: `https://${isHKFirstOrigin}.${env}.hashkeydev.com`,
  production: 'https://www.hashkey.com'
};
// passport host
const webPP:any = {
  [env]: `https://passport.${env}.hashkeydev.com`,
  production: 'https://passport.hashkey.com'
};

export const apiHost = {
  api: apis[env],
  ws: wss[env],
  app: apps[env],
  web: web[env],
  webHK: webHK[env],
  webPP: webPP[env]
};

// 稳定币定义
export const stableToken = ['USDC', 'DAI'];

const apiLangMap: any = {
  'zh-CN': 'cn',
  'en-US': 'en'
};

export const getApiUrl = () => {
  const localeLang = defaultLang;
  const lang = localeLang && apiLangMap[localeLang] ? apiLangMap[localeLang] : 'en';

  return `https://Hashkeydocs.github.io/apidocs/spot/v1/${lang}/#45fa4e00db`;
};

// 获取默认语言
export const getDefaultLang = () => {
  const localStoreLang: string = localStorage.getItem(STORE_LANG) || lang;
  const langInfo = siteConfig.supportLanguagesMap[localStoreLang];

  return {
    langKey: localStoreLang,
    ...langInfo
  };
};

// 当前环境的location属性
export const envHost: any = isClient
  ? window.location.host
  : {
    [env]: `global.${env}.hashkeydev.com`,
    production: 'global.hashkey.com'
  }[env];
export const envHref: string = isClient ? window.location.href : `https://${envHost}/`;
export const envOrigin: string = isClient ? window.location.origin : `https://${envHost}`;

// APP 相关配置
const APP_KEY = 'hashkeyglobal';
const APP_PROTOCOL = 'www.hashkey.com';
export const appConfig = {
  APP_KEY,
  APP_PROTOCOL,
  APP_COMMON_PREFIX: `${APP_KEY}://${APP_PROTOCOL}`
};

export const downloadConfig: any = {
  webview: (path: any) => `${appConfig.APP_KEY}://${appConfig.APP_PROTOCOL}/webview?url=${envOrigin}${path}&needReload=true`,
  webviewToH5: (path: any) => `${envOrigin}${path}`,
  androidUrl: `${envOrigin}/download`,
  iOSUrl: `${envOrigin}/download`,
  duration: 3000
};
