/**
 * @file 管理切换路由时 需要设置的状态
 */
// eslint-disable-next-line
// @ts-nocheck

// import { RouteLocationNormalized, NavigationGuardNext } from 'vue-router';
import { pinia } from '@/store';
import { getOriginPath } from '@/lang/i18n';
import { useSpotStore } from '@/store/spot';
import { useConfigStore } from '@/store/config';
import { useLayoutStore } from '@/store/layout';
import { useTokenStore } from '@/store/token';
import { supportLang, i18n } from '@/lang/i18n';
import { useCommonStore } from '@/store/common';
import { useUserStore } from '@/store/User';
import { isPC } from '@/utils/tools';

const { t } = i18n.global;

export default {
  beforeEach: [
    async({ matched: toMatched, meta, path, params }: RouteLocationNormalized, { matched: fromMatched, path: fromPath }: RouteLocationNormalized, next: NavigationGuardNext):void => {
      const spotStore = useSpotStore(pinia);
      const configStore = useConfigStore(pinia);
      const tokenStore = useTokenStore(pinia);
      const common = useCommonStore();
      const userStore = useUserStore();
      const pathLang = path.split('/')[1];
      const fromPathLang = fromPath.split('/')[1];
      // common.isLoading = true;
      // 第一期默认只有light
      // localStorage.setItem('isDark', 'light');

      // 交易页交易对tab切换状态重置
      userStore.changeCurrentTime(1); // 计时器设置成1
      if (['/spot', '/spot/:symbolPath'].includes(getOriginPath(toMatched[0]?.path))) {
        if (!['/spot', '/spot/:symbolPath'].includes(getOriginPath(fromMatched[0]?.path))) {
          spotStore.switchSymbolTabEnable = true;
        }
        userStore.checkUserActive(true); // 检查用户会话超时 3181
      } else {
        userStore.checkUserActive(); // 检查用户会话超时 3181
      }

      // ssr 状态预加载
      if (import.meta.env.SSR) {
        const preloadList = meta?.ssg?.preload || [];
        for (const preload of preloadList) {
          await preload();
        }
      }

      // 币种详情页提前请求
      if (getOriginPath(toMatched[0]?.path) === '/price/:symbolPath') {
        if (params.symbolPath) {
          await tokenStore.getTokenInfo(params.symbolPath);
        }
      }
      // 限制 IP （临时方案，需要换接口）
      if (getOriginPath(path) != '/limit') {
        if (pathLang !== fromPathLang && !configStore.homeConfigReady[pathLang.toLowerCase()]) {
          if(isPC()) {
            await configStore.getHomeConfig(supportLang(pathLang) ? pathLang.toLowerCase() : '');
          }
        }
        if (!configStore.isFetchConfig) {
          configStore.getConfig();
          await configStore.getIpBlack();
          configStore.isFetchConfig = true;
        }
        if (configStore.ipBlack?.code === 1) {
          next({ path: '/limit' });
        } else {
          next();
        }
      } else {
        next();
      }
    }
  ],
  afterEach: [
    ({ meta, path }, from) => {
      const layoutStore = useLayoutStore(pinia);
      const common = useCommonStore();
      const defaultKeywords = 'HashKey, HSK, Crypto, Exchange, Cryptocurrency, BTC, ETH, USDT, USDC, SFC, Vritual Assest, Tokens, Ethereum, USD, HKD, 比特币, 以太坊, 虚拟币, 数字货币, 交易所';
      const defaultDescription = 'Welcome to the HashKey Global, where you can conveniently trade between fiat and cryptocurrencies. As a highly recognized digital asset trading platform, we provide a safe and secure trading environment based on licensing compliance. Through HashKey Global, you will enjoy a fast, secure and convenient trading experience.';
      // 初始化route布局及对应参数
      layoutStore.initRouteLayout(meta);
      // 页面Title
      layoutStore.pageTitle = meta?.title;

      // 获取原始路由path(剔除语言)
      var oriPath = `/${path.split('/').slice(2).join('/')}`;

      // 改写canonical和alternate的url
      const hostUrl = 'https://global.hashkey.com';
      const langs = ['zh-CN', 'zh-TW', 'en-US'];
      
      const canonicalDom = document.querySelector('link[rel="canonical"]');
      canonicalDom?.setAttribute('href', `${hostUrl}${oriPath}`);
      langs.map(lang => {
        const langDom = document.querySelector(`link[hreflang=${lang}]`);
        if (langDom) {
          langDom.setAttribute('href', `${hostUrl}/${lang}${oriPath}`);
        }
      });
      
      // common.isLoading = false;
      if (!meta.theme) {
        document.documentElement.setAttribute('class', 'light');
      }

      const keywordsMeta = document.querySelector('meta[name="keywords"]');
      const descriptionMeta = document.querySelector('meta[name="description"]');

      // og和twitter
      const ogTitle = document.querySelector('meta[property="og:title"]');
      const ogDesc = document.querySelector('meta[property="og:description"]');
      const ogUrl = document.querySelector('meta[property="og:url"]');

      const twitterSite = document.querySelector('meta[property="twitter:site"]');
      const twitterTitle = document.querySelector('meta[property="twitter:title"]');
      const twitterDesc = document.querySelector('meta[property="twitter:description"]');

      ogUrl?.setAttribute('content', `${hostUrl}${path}`);
      twitterSite?.setAttribute('content', `${hostUrl}${path}`);

      if (meta?.title) {
        ogTitle?.setAttribute('content', `${t(meta.title)}`);
        twitterTitle?.setAttribute('content', `${t(meta.title)}`);
      }

      if (meta.keywords) {
        keywordsMeta?.setAttribute('content', `${t(meta.keywords)}`);
      } else {
        if (!from.meta.keywords) {
          keywordsMeta?.setAttribute('content', defaultKeywords);
        }
      }

      if (meta.description) {
        descriptionMeta?.setAttribute('content', `${t(meta.description)}`);
        ogDesc?.setAttribute('content', `${t(meta.description)}`);
        twitterDesc?.setAttribute('content', `${t(meta.description)}`);
      } else {
        if (!from.meta.description) {
          descriptionMeta?.setAttribute('content', defaultDescription);
        }
      }

      if (!import.meta.env.SSR) {
        // 滚动条重置
        document.documentElement.scrollTo(0, 0);
        document.body.scrollTo(0, 0);
      }
    }
  ]
};
